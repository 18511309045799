import { css } from 'astroturf';
import React, { Component } from 'react';
import CTA from '../../components/CTA';
import PageHeader from '../../components/PageHeader';
import SEO from '../../components/SEO';
import Layout from '../../layout';
import FeatureList from './_FeatureList';

const styles = css`
  .icon {
    max-width: 100%;
    width: 10rem;
    --white: #fff;
    margin-bottom: 2rem;
  }
`;

class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO title="The perfect browser for Accessibility" keywords={['']} pathname={this.props.location.pathname} />
        <PageHeader>
          <svg className={styles.icon} viewBox="0 0 111 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <linearGradient id="a11ygradient" gradientTransform="rotate(45)">
                <stop offset="0" stopColor="rgb(44, 130, 173)" stopOpacity="1" />

                <stop offset="1" stopColor="rgb(103, 178, 111)" stopOpacity="1" />
              </linearGradient>
            </defs>
            <rect x="0.5" width="111" height="60" rx="10" fill="url(#a11ygradient)" />
            <g transform="translate(8 -3) scale(1.1)">
            <path
              d="M22.5 18.5C22.5 21 24.5 23 27 23C29.4375 23 31.5 21 31.5 18.5C31.5 16.0625 29.4375 14 27 14C24.5 14 22.5 16.0625 22.5 18.5ZM38.375 18.625C37.625 17.8125 36.3125 17.8125 35.5625 18.625L30.125 24H23.8125L18.375 18.625C17.625 17.8125 16.3125 17.8125 15.5625 18.625C14.75 19.375 14.75 20.6875 15.5625 21.4375L21.5 27.375V44C21.5 45.125 22.375 46 23.5 46H24.5C25.5625 46 26.5 45.125 26.5 44V37H27.5V44C27.5 45.125 28.375 46 29.5 46H30.5C31.5625 46 32.5 45.125 32.5 44V27.375L38.375 21.4375C39.1875 20.6875 39.1875 19.375 38.375 18.625Z"
              fill="var(--white)"
            />
            <path
              d="M54.5 18.5C54.5 21 56.5 23 59 23C61.4375 23 63.5 21 63.5 18.5C63.5 16.0625 61.4375 14 59 14C56.5 14 54.5 16.0625 54.5 18.5ZM70.375 18.625C69.625 17.8125 68.3125 17.8125 67.5625 18.625L62.125 24H55.8125L50.375 18.625C49.625 17.8125 48.3125 17.8125 47.5625 18.625C46.75 19.375 46.75 20.6875 47.5625 21.4375L53.5 27.375V44C53.5 45.125 54.375 46 55.5 46H56.5C57.5625 46 58.5 45.125 58.5 44V37H59.5V44C59.5 45.125 60.375 46 61.5 46H62.5C63.5625 46 64.5 45.125 64.5 44V27.375L70.375 21.4375C71.1875 20.6875 71.1875 19.375 70.375 18.625Z"
              fill="var(--white)"
            />
            </g>
          </svg>
          <h1>All the A11y tools you need.</h1>
          <h2>
            From color blindness simulators to network throttling, from horizontal overflow detection to color contrast
            suggestions. Polypane has all the tools you need to check how accessible your site is.
          </h2>
        </PageHeader>
        <FeatureList />
        <CTA />
      </Layout>
    );
  }
}

export default Page;
