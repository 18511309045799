import { css } from 'astroturf';
import { Link } from 'gatsby';
import React from 'react';
import { ArrowRight } from 'react-bytesize-icons';

const styles = css`
  .post {
    color: #000;
    max-width: 50rem;
    width: 100%;
    margin: 0 auto 8rem;
    position: relative;
    text-align: left;
    display: block;
    padding: 2rem;
    font-weight: normal;
    padding-left: calc(2rem + env(safe-area-inset-left, 0));
    padding-right: calc(2rem + env(safe-area-inset-right, 0));

    box-shadow: initial;
    transition: 0.25s ease-in-out box-shadow;

    &:hocus {
      background: none;
      box-shadow:
        rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

      & img {
        filter: drop-shadow(0 10px 10px rgba(50, 50, 93, 0.3));
        transform: scale(1) translateY(-4px);
      }
    }

    & .button {
      color: #107db5;
    }

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      & img {
        width: 80%;
        max-width: 128px;
        height: auto;
        margin-bottom: 1rem;
        transform: scale(0.95);
        transition:
          0.25s ease-in-out filter,
          0.25s ease-in-out transform;
      }
    }

    @media (min-width: 60rem) {
      margin-left: auto;
      margin-right: auto;

      & > div {
        position: relative;
        flex-direction: row;
        align-items: flex-start;

        &.hasImg {
          padding-left: 250px;
        }

        & img {
          left: -5rem;
          position: absolute;
          width: 256px;
          max-width: 256px;
        }
      }
    }

    & h3 {
      font-size: calc(16px + 0.8vw);
      line-height: 1.2;
      font-weight: 900;
      margin-bottom: 0.5rem;
      color: #0a1530;
    }

    & span.meta {
      display: block;
      opacity: 0.6;
      margin-bottom: 0.5rem;
    }

    & p {
      opacity: 0.6;
      @media (min-width: 60rem) {
        opacity: 0.9;
      }
    }

    & .button {
      position: relative;
      display: block;
      pointer-events: all;
      margin: 1rem 0 0;
      font-size: calc(16px + 0.4vw);
      border-bottom: 1px solid #000;
      line-height: 1.25;
      padding: 0.33em 0;
      display: inline-block;
      text-decoration: none;
      line-height: 26px;
      transition: all 0.25s ease-in-out;
      font-weight: 800;

      @media (min-width: 600px) {
        font-size: 1.4rem;
      }

      &::after {
        position: absolute;
        right: 0px;
        bottom: -1px;
        content: ' ';
        width: 100%;
        height: 1px;
        background: #fff;
        transition: 0.25s ease-out width;
      }

      & svg {
        transform: translateX(0);
        transition: 0.2s 0.12s ease-out;
      }

      & svg {
        vertical-align: bottom;
      }
    }

    &:hocus .button {
      color: #000;
      text-decoration: none;

      & svg {
        transform: translateX(4px);
      }

      &::after {
        width: 0;
      }
    }
  }
`;

class Blog extends React.Component {
  render() {
    return (
      <Link to="/blog/find-and-fix-accessibility-issues-with-polypane/" className={[styles.post].join(' ')}>
        <div className={styles.hasImg}>
          <img src="/blogs/a11y/cover.svg" alt="" />
          <div>
            <h3>Find and fix accessibility issues with Polypane</h3>
            <span className={styles.meta}>
              12 min read. First posted on{' '}
              {new Date('2021-01-19').toLocaleDateString(undefined, {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </span>
            <p>
              At Polypane, accessibility is one of the three core areas we focus on, along with performance and
              responsive design. If you're not familiar with Polypane, it's a web browser specifically for web
              developers. It has all…
            </p>
            <span className={styles.button}>
              Read Article
              <ArrowRight height={24} />
            </span>
          </div>
        </div>
      </Link>
    );
  }
}

export default Blog;
