import { css } from 'astroturf';
import { StaticQuery, graphql } from 'gatsby';
import React from 'react';
import SmallCTA from '../../components/CTA-small-platforms';
import Feature from '../../components/Feature';
import FeatureVideo from '../../components/FeatureVideo';
import Blog from './_Blog';

const styles = css`
  .featureList {
    padding: 0rem 0 5rem;
    text-align: center;

    & > h2 {
      padding: 5rem 2rem 0rem;
      font-weight: 900;
      font-size: clamp(18px, 16px + 3vw, 40px);
      letter-spacing:-0.015em;
      color: #0a1530;
      max-width: 80rem;
      margin-left: auto;
      margin-right: auto;
    }
    & > p {
      font-size: calc(16px + 0.25vw);
      font-weight: 400;
      position: relative;
      max-width: 52rem;
      margin: 0 auto 1.5rem;
      line-height: 1.6;

      padding-left: calc(1rem + env(safe-area-inset-left, 0));
      padding-right: calc(1rem + env(safe-area-inset-right, 0));
      @media (min-width: 770px) {
        font-size: 1.17rem;
      }
    }

    & li {
      color: #0a1530;
    }
  }

  .primaryButton {
    pointer-events: all;
    margin: 0;
    font-size: calc(14px + 0.5vw);
    font-weight: 800;
    color: rgba(255, 255, 255, 1);
    background: #107db5;
    border: 0px solid #107db5;
    padding: 0.33em 1rem;
    border-radius: 8px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    line-height: 26px;
    transition: all 0.25s ease-in-out;
    overflow: hidden;
    white-space: nowrap;
    margin-left: -4px;

    & span {
      position: relative;
      z-index: 1;
    }

    &::before {
      content: ' ';
      display: block;
      background: linear-gradient(135deg, #4ca2cd, #67b26f);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
      border-radius: 8px;
    }

    @media (min-width: 600px) {
      font-size: 16px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 2rem;
      padding-right: 2rem;
      line-height: 40px;
      height: 40px;
    }

    &:hocus {
      text-decoration: none;
      &::before {
        opacity: 1;
      }
    }
  }

  .badge {
    vertical-align: middle;
    font-size: 0.8rem;
    line-height: 0.8rem;
    background: #107db5;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    padding: 3px 5px 1px;
  }
`;

// Color blindness simulators
//
// Color contrast tester
//
// Accessibility issue
//
// Page outlines [new]
//
// reduced motion testing
//
// Visual impairment simulators
//
// Reachability
//
// Accessibility overlays
//
// Horizontal overflow detection
//
// Network throttling
//
// highlight node accessible name, contrast etc

function FeatureList() {
  return (
    <StaticQuery
      query={photoquery}
      render={(data) => (
        <section className={styles.featureList}>
          <Feature gatsbyImg={data.colorblindness} type="light" full brow="Debug tools">
            <h3>Simulate color blindness</h3>

            <p>Simulate 8 different types of color blindness directly in Polypane, side by side.</p>
          </Feature>

          <FeatureVideo screenshot={data.contrast2} type="light" brow="Debug tools">
            <h3>Test your color contrast automatically</h3>
            <p>
              Find all elements that don't have enough contrast using the real text and background colors. We'll suggest
              improved colors too!
            </p>
          </FeatureVideo>

          <Feature screenshot={data.a11ytest} type="light" full brow="Accessibility panel">
            <h3>Automated accessibility test</h3>
            <p>Over 80 different A11y tests. WCAG 2.1 and actionable feedback included.</p>
            <p>
              The score in this panel maps to your Lighthouse/web.dev score, so you know if you're on the right track.
            </p>
          </Feature>

          <FeatureVideo screenshot={data.headers} type="light" brow="Outline panel">
            <h3>
              Your page structure visualized
            </h3>
            <p>Find broken links, incorrect header orders, Focus order issues, missing accessible names and more.</p>
          </FeatureVideo>

          <Feature gatsbyImg={data.nodetooltip} full type="light" brow="Element inspector">
            <h3>A11y info at your fingertips</h3>
            <p>
              Whether in the element inspector or in the node selection tooltip, Polypane will show you the a11y info
              for each element, like contrast, accessible name, role and warnings for missing attributes.
            </p>
          </Feature>

          <FeatureVideo screenshot={data.darkmode} type="light" large brow="Emulation">
            <h3>Dark mode, reduced motion &amp; reduced data testing</h3>
            <p>
              Toggle dark mode, reduced motion and reduced data for any pane with a single click and test it
              side-by-side with the regular site. No need to change your entire OS.
            </p>
          </FeatureVideo>

          <SmallCTA />

          <Feature screenshot={data.dyslexia} type="light" full brow="Debug tools">
            <h3>Simulate visual impairments</h3>
            <p>
              Polypane simulates farsightedness, glaucoma and cataracts but also dyslexia, bright sunlight and night
              mode. Easily accessible through the debug tools.
            </p>
          </Feature>

          <Feature screenshot={data.reachability} type="light" full brow="Debug tools">
            <h3>Reachability</h3>
            <p>
              Check your mobile designs on how easy elements are to reach single-handedly. Comes in righty and lefty
              versions.
            </p>
          </Feature>

          <Feature screenshot={data.overflow} type="light" full brow="Layout debugging">
            <h3>Horizontal overflow detection</h3>
            <p>
              Turn layout debugging on and Polypane highlights all elements causing a horizontal overflow, no messing
              around in the devtools needed. Quickly check that a page adheres to WCAG 1.4.10 "Reflow".
            </p>
          </Feature>

          <Feature screenshot={data.disablecss} type="light" full brow="Debug tools">
            <h3>Disable CSS</h3>
            <p>Quickly Disable all the CSS in a single pane to clearly see your HTML structure.</p>
          </Feature>

          <Feature screenshot={data.disableimages} type="light" full brow="Debug tools">
            <h3>Disable Images</h3>
            <p>Hide images and show their alt text instead to make sure your layout can handle broken images.</p>
          </Feature>

          <h2>Want to learn more?</h2>
          <p>We wrote about all the ways Polypane improves the accessibility of your site.</p>

          <Blog />
        </section>
      )}
    />
  );
}

export default FeatureList;

const photoquery = graphql`
  query a11yFeaturesPageQuery {
    colorblindness: file(relativePath: { eq: "images/colorblindness.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(breakpoints: [320, 375, 768, 1024, 1024], quality: 100, layout: FULL_WIDTH)
      }
    }
    contrast: file(relativePath: { eq: "images/image12.gif" }) {
      publicURL
    }
    contrast2: file(relativePath: { eq: "videos/copy-color.mp4" }) {
      publicURL
    }
    a11ytest: file(relativePath: { eq: "images/a11ytest.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(breakpoints: [320, 375, 768, 1024, 1024], quality: 100, layout: FULL_WIDTH)
      }
    }
    headers: file(relativePath: { eq: "videos/marketers/headers.mp4" }) {
      publicURL
    }
    dyslexia: file(relativePath: { eq: "images/dyslexia.gif" }) {
      publicURL
    }
    reachability: file(relativePath: { eq: "images/reachability.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(breakpoints: [320, 375, 768, 1024, 1024], quality: 100, layout: FULL_WIDTH)
      }
    }
    disablecss: file(relativePath: { eq: "images/disable-css.jpg" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(breakpoints: [320, 375, 768, 1024, 1024], quality: 100, layout: FULL_WIDTH)
      }
    }
    disableimages: file(relativePath: { eq: "images/disable-images.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(breakpoints: [320, 375, 768, 1024, 1024], quality: 100, layout: FULL_WIDTH)
      }
    }
    nodetooltip: file(relativePath: { eq: "images/node-tooltip.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(breakpoints: [320, 375, 768, 1024, 1024], quality: 100, layout: FULL_WIDTH)
      }
    }
    overflow: file(relativePath: { eq: "images/overflow.gif" }) {
      publicURL
    }
    devicewall: file(relativePath: { eq: "videos/marketers/devicewall.mp4" }) {
      publicURL
    }
    syncedactions: file(relativePath: { eq: "videos/marketers/syncedactions.mp4" }) {
      publicURL
    }
    debugtools: file(relativePath: { eq: "videos/marketers/debugtools.mp4" }) {
      publicURL
    }
    metadata2: file(relativePath: { eq: "videos/marketers/metadata2.mp4" }) {
      publicURL
    }
    screenshots: file(relativePath: { eq: "videos/marketers/screenshot.mp4" }) {
      publicURL
    }
    darkmode: file(relativePath: { eq: "videos/marketers/darkmode.mp4" }) {
      publicURL
    }
    links: file(relativePath: { eq: "videos/marketers/links.mp4" }) {
      publicURL
    }
    liveVideo: file(relativePath: { eq: "videos/livecss.mp4" }) {
      publicURL
    }
    deviceVideo: file(relativePath: { eq: "videos/devices.mp4" }) {
      publicURL
    }
    breakpointVideo: file(relativePath: { eq: "videos/breakpoints.mp4" }) {
      publicURL
    }
    browseVideo: file(relativePath: { eq: "videos/browse.mp4" }) {
      publicURL
    }
    devtoolsVideo: file(relativePath: { eq: "videos/devtools.mp4" }) {
      publicURL
    }
    liveReloadVideo: file(relativePath: { eq: "videos/live-reload.mp4" }) {
      publicURL
    }
    handoffVideo: file(relativePath: { eq: "videos/handoff.mp4" }) {
      publicURL
    }
    zoomVideo: file(relativePath: { eq: "videos/zooming.mp4" }) {
      publicURL
    }
    modesVideo: file(relativePath: { eq: "videos/modes.mp4" }) {
      publicURL
    }
    referenceVideo: file(relativePath: { eq: "videos/reference.mp4" }) {
      publicURL
    }
    overlaysVideo: file(relativePath: { eq: "videos/overlays.mp4" }) {
      publicURL
    }
    more: file(relativePath: { eq: "images/building_website.svg" }) {
      publicURL
    }
    darklight: file(relativePath: { eq: "images/overview-split.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(breakpoints: [320, 375, 768, 1024, 1024], quality: 100, layout: FULL_WIDTH)
      }
    }
    devex: file(relativePath: { eq: "images/devtools-extensions.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(breakpoints: [320, 375, 768, 1024, 1024], quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`;
