import { css } from 'astroturf';
import React from 'react';
import { InView } from 'react-intersection-observer';

const styles = css`
  .feature {
    padding: 5rem 2rem;
    max-width: 1200px;
    margin: 0 auto;

    @media (min-width: 800px) {
      padding: 8rem 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;

      & video {
        transition: 1s transform ease;
        transform: perspective(800px) rotateY(-0.1deg);
        transform-style: preserve-3d;
        will-change: transform;

        &:hover {
          transform: perspective(800px) rotateY(-4deg);
        }
      }

      &:nth-child(even) {
        flex-direction: row-reverse;

        & .text {
          @media (min-width: 800px) {
            padding: 0 0 0 4rem;
          }
        }
        & video {
          transform: perspective(800px) rotateY(0.1deg);
          &:hover {
            transform: perspective(800px) rotateY(4deg);
          }
        }

        & .videocontainer::before {
          background: linear-gradient(to right bottom, rgb(114, 198, 239), rgb(0, 78, 143));
          filter: url(#noise);
        }
      }
    }

    & > div {
      position: relative;
      flex: 1 1 0%;

      &:last-child {
        flex: 2 1 0%;
      }
    }
    &.light .text {
      & h3 {
        color: #0a1530;
      }
      & p,
      & blockquote {
        color: #000;
      }
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    padding-bottom: 2rem;

    @media (min-width: 800px) {
      padding: 0 4rem 0 0;
    }
    & h3 {
      width: 100%;
      text-align: left;
      max-width: 30rem;
      font-weight: 900;
      font-size: clamp(18px, 16px + 3vw, 40px);
      line-height: 1.1;
      color: #fff;
      margin: 0 auto;
      text-wrap: balance;
    }
    & blockquote,
    & p {
      margin: 2rem auto 0;
      max-width: 30rem;
      color: rgba(255, 255, 255, 0.9);
      text-align: left;
      width: 100%;
      font-size: calc(16px + 0.25vw);
      @media (min-width: 770px) {
        font-size: 1.17rem;
      }
    }

    & .brow {
      align-self: left;
      text-align: left;
      margin: 0 auto;
      max-width: min(100%, 30rem);
      width: 30rem;
      color: #007ca5;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.8em;
      background: radial-gradient(at center, #67b26f, ease-in-out, #007ca5);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    & blockquote {
      font-style: italic;

      & p {
        padding: 0;
        margin: 0 0 1rem;
      }

      & cite {
        display: flex;
        align-items: center;
        align-items: center;

        & span {
          padding-left: 1rem;
          flex: 1;
          font-weight: bold;
          & small {
            font-weight: normal;
            display: block;
          }
        }
      }

      & img {
        border-radius: 100%;
      }
    }
  }

  .videocontainer {
    position: relative;
    flex-grow: 1;
    height: 100%;
    aspect-ratio: 100/59.02;

    &.large {
      aspect-ratio: 100/63.22;
    }

    &::after {
      z-index: 2;
      content: '▶';
      place-items: center;
      position: absolute;
      display: block;
      left: calc(50% - 50px);
      top: calc(50% - 50px);
      font-size: 50px;
      width: 100px;
      height: 100px;
      line-height: 100px;
      text-align: center;
      color: #fff;
      pointer-events: none;
      opacity: 0;
      transition: 0.2s ease-in-out;
      border-radius: 9999px;
      background: rgba(0, 0, 0, 0.7);
      backdrop-filter: blur(6px);
      padding-left: 8px;
      box-shadow: rgba(255, 255, 255, 0.15) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.3) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.5) 0px 30px 60px -30px, rgba(103, 178, 111, 0.3) -10px 10px 60px -10px;
      transform: translateZ(100px);
      filter: url(#noise);
    }

    &.paused::after {
      opacity: 0.95;
      transition: 0.1s ease-in-out;
    }
  }

  .videocontainer::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 101%;
    height: 101%;
    border-radius: 4px;
    background: linear-gradient(
      to right bottom,
      rgb(31, 0, 92),
      rgb(91, 0, 96),
      rgb(135, 1, 96),
      rgb(172, 37, 94),
      rgb(202, 72, 92),
      rgb(225, 107, 92),
      rgb(243, 144, 96),
      rgb(255, 181, 107)
    );
    transform: rotate(4deg) translateZ(-40px);
    transform-origin: 20% 70%;
    box-shadow: rgba(255, 255, 255, 0.15) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.3) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.5) 0px 30px 60px -30px, rgba(103, 178, 111, 0.3) -10px 10px 60px -10px;
  }

  .video {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: #f0eef0;
    object-fit: cover;
    box-shadow: rgba(255, 255, 255, 0.15) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.3) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.5) 0px 30px 60px -30px, rgba(103, 178, 111, 0.3) -10px 10px 60px -10px;
  }
`;

export default class Feature extends React.Component {
  state = {
    playing: false,
  };

  componentDidMount() {}

  handleClick = () => {
    if (this.video.paused) {
      this.video.play();
      this.setState({ playing: true });
    } else {
      this.video.pause();
      this.setState({ playing: false });
    }
  };

  render() {
    return (
      <InView
        threshold={0.75}
        onChange={(inView) => {
          let promise;
          const shouldPauseOnFirstFrame = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

          if (inView) {
            promise = this.video.play();
            this.setState({ playing: true });
            if (shouldPauseOnFirstFrame) {
              this.video.pause();
              this.setState({ playing: false });
            }
          } else {
            this.setState({ playing: false });
            promise = this.video.pause();
          }

          if (promise !== undefined) {
            promise.catch((error) => {}).then(() => {});
          }
        }}
      >
        {({ ref }) => (
          <div ref={ref} className={[styles.feature, this.props.type ? styles[this.props.type] : ''].join(' ')}>
            <div className={styles.text}>
              {this.props.brow && <span className={styles.brow}>{this.props.brow}</span>}
              {this.props.children}
            </div>
            <div
              className={[
                styles.videocontainer,
                this.state.playing ? styles.playing : styles.paused,
                this.props.large ? styles.large : '',
              ].join(' ')}
            >
              <video
                className={styles.video}
                loop
                muted
                preload="true"
                loading="lazy"
                playsInline
                ref={(c) => {
                  this.video = c;
                }}
                onClick={this.handleClick}
                title="Click to play"
              >
                <source src={this.props.screenshot.publicURL} type="video/mp4" />
              </video>
            </div>
          </div>
        )}
      </InView>
    );
  }
}
